<template>
<div class="container-fluid">
    <breadcrumb>
    <img  src="static/img/country.svg" class="breadcrumb-image" />
      <breadcrumb-item>
        <router-link to="/countries">Countries</router-link>
      </breadcrumb-item>
      <breadcrumb-item v-if="add" active> Add Country </breadcrumb-item>
      <breadcrumb-item v-if="add == false" active>
        Edit Country
      </breadcrumb-item>
    </breadcrumb>
  <form autocomplete="off">
    <card v-if="loaded">
      <div>
        <fg-input
          name="name"
          label="Name"
          v-validate="modelValidations.name"
          :error="getError('name')"
          v-model="model.name"
        ></fg-input>
        <fg-input label="Level of Exposure" :error="getError('exposure level')">
          <el-select
            name="exposure level"
            v-validate="modelValidations.exposureLevel"
            class="select-default"
            style="width: 100%"
            v-on:input="ddlExposureLevelChange"
            v-model="model.exposureLevel"
            placeholder="Select Exposure Level"
          >
            <el-option
              class="select-default"
              v-for="item in  model.exposureLevels"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </fg-input>
        <checkbox v-model="checkboxValue">Active</checkbox>
      </div>
      <div class="card-footer text-right">
        <button type="button" @click="close" class="btn btn-fill btn-danger btn-wd">
          <span class="btn-label">
            <i class="fa fa-times"></i>
          </span>
          Cancel
        </button>
        <button
          type="submit"
          @click.prevent="validate"
          @click="save"
          class="btn btn-fill btn-info btn-wd"
        >
          <span class="btn-label">
            <i class="fa fa-save"></i>
          </span>
          Save
        </button>
      </div>
    </card>
  </form>
</div>
</template>

<script>
import { Checkbox } from "src/components/index";
import { Select, Option } from "element-ui";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";

export default {
  components: {
    Checkbox,
    [Option.name]: Option,
    [Select.name]: Select,
    Breadcrumb,
    BreadcrumbItem
  },
  data() {
    return {
      checkboxValue: true,
      model: {
        id: 0,
        exposureLevel: "",
        isActive: true,
        exposureLevels: ["Very Low", "Low", "Medium", "High", "Very High"]
      },
      modelValidations: {
        name: {
          required: true
        },
        exposureLevel: {
          required: true
        }
      },
      loaded: false,
      add: false,
    };
  },
  created() {
    let self = this;
    self.get(self.$route.params.id);
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        this.$emit("on-submit", this.country, isValid);
      });
    },
    get(id) {
      let self = this;
      if (id == undefined) return;
      self.$store.state.services.countryService
        .get(id)
        .then(r => {
          self.model.id = r.data.id;
          self.model.name = r.data.name;
          self.model.exposureLevel = r.data.exposureLevel;
          var myValue = r.data.isActive == "True";
          self.model.isActive = myValue;
          self.checkboxValue = myValue;
          self.loaded = true;
        })
        .catch(err => console.log(err));
    },
    save() {
      let self = this;
      self.$validator.validateAll().then(valid => {
        if (valid) {
          if (self.model.id > 0) {
            self.model.isActive = self.checkboxValue.toString();
            self.model.exposureLevel = self.model.exposureLevel.replace(/\s/g, '');
            self.$store.state.services.countryService
              .update(self.model)
              .then(r => {
                self.notifyVue ('top', 'right', 'success');
                self.close();
              })
              .catch(err => console.log(err));
          }
        }
      });
    },
    close(){
      let self = this;
      self.$router.push("/countries");
    },
    ddlExposureLevelChange(value) {
      let self = this;
      self.model.exposureLevel = value;
    },
    notifyVue (verticalAlign, horizontalAlign, messageType) {
        this.$notify(
          {
            component: {
              template: `<span></br>Country saved</br>&nbsp;</span>`
            },
            icon: 'fa fa-check',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: messageType
          })
      }
  }
};
</script>